import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import napolean from "../images/hawthorne/napolean.webp";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Quick Guide to Data Visualizations" keywords={['gatsby', 'application', 'react', 'guide', 'data', 'visualizations', 'graphs', 'charts']} mdxType="SEO" />
    <h1>{`Quick Guide to Data Visualizations`}</h1>
    <h2>{`July 13, 2021`}</h2>
    <p>{`I’ve been mulling over this guide for so long. I wanted to draw specific examples and showcase interesting scenarios. But, the specifics don’t matter. Here are 6 rules to making your data visualizations valuable, useful, and something that would NOT show up in an anime filler episode.`}</p>
    <p><strong parentName="p">{`1. Just use a table`}</strong>{` ~ Sometimes it’s way more efficient to just read a table. Do you only have 2 parameters? Do you have less than 20 data points? Just stick to a table.`}</p>
    <p><strong parentName="p">{`2. Make it dense`}</strong>{` ~ Stick like a million data points into your visualizations. Seriously don’t be afraid. The human brain can handle it. Interesting patterns and intricate stories will start emerging from them.`}</p>
    <p><strong parentName="p">{`3. Increase the range`}</strong>{` ~ Don’t just show one month, show an entire year. Stories are told over time.`}</p>
    <p><strong parentName="p">{`4. Layer parameters`}</strong>{` ~ How do you take a graph that shows the crime rate in a city over a year to the next level? Layer some weather data over that graph. Now you’ll see that hotter weather correlates with higher crime rates.`}</p>
    <p>{`If you can’t physically layer it, combine it in different ways. Get creative. This famous graph below has like literally a dozen parameters.`}</p>
    <img height="277px" loading="lazy" src={napolean} alt="Napolean's march." className="center" />
    <div className="caption">A graph of Napolean's army withering away in a harsh Russian climate.</div>
    <p><strong parentName="p">{`5. Don’t use crappy formats`}</strong>{` ~ Pie charts and bar graphs suck. Their scaling can be arbitrary, so they end up lying inadvertently. You can’t make them as dense or clean as other formats. Out of all the data visualization types, they use the most cognitive load.`}</p>
    <p><strong parentName="p">{`6. Get good at visual design`}</strong>{` ~ This one takes experience. Good visual design isn’t about making something looking trendy or cool. It’s about knowing what visual details make something more useful while still setting the right mood. It’s a hard thing to do.`}</p>
    <p>{`You may be thinking, “But there are no rules in design! These must be guidelines right?”. Yeah, they’re guidelines, but let’s pretend they’re rules. Society sucks at data visualization, so we gotta overcorrect.`}</p>
    <p>{`I learned all of this from reading books by statistician and Yale University professor Edward Tufte.`}</p>
    <Link to="/" mdxType="Link">Home</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      